import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";

const Header = () => {
  return (
    <div className="flex w-[100%]  h-[8vh] relative justify-center items-center bg-[#F7F7F7] text-[#242424]">
      <div className="sm:w-[45vw] w-[100%] flex gap-2">
        <div className=" flex items-center justify-start sm:pl-[25px]">
          <BsFillTelephoneFill className="mx-[10px] sm:text-[24px]" />
          <p className="sm:text-[18px]  text-[3vw]">
            Tel: <a href="tel:+994504071417">(+994) 50 407 14 17</a>
          </p>
        </div>
      </div>
      <div className="h-[100%] sm:w-[45vw] w-[100%] flex items-center justify-end sm:pr-[30px] pr-5">
        <HiOutlineMail className="mr-[10px] text-[24px] hidden lg:block" />
        <p className="hidden lg:block">
          Email: <a href="mailto:info@kabelmarket.az">info@kabelmarket.az</a>
        </p>
        <button className="ml-[40px] bg-yellow-400 text-[#000] sm:text-[15px] text-[3vw] px-[15px] font-medium tracking-wider py-[8px] rounded-[6px]">
          <a href="/#contact">Bizimlə Əlaqə</a>
        </button>
      </div>
    </div>
  );
};

export default Header;
