import React from "react";

const Banner = () => {
  return (
    <>
      <div id="about" className="max-w-[90vw] w-[100%] h-[100%] sm:min-h-[45vh] px-0 py-16 mx-auto sm:px-6 lg:px-8 flex justify-center items-center">
        <section className="overflow-hidden sm:min-h-[42vh]  rounded-lg shadow-2xl md:grid md:grid-cols-3">
          <img
            alt="kabel"
            src="https://www.endesa.com/content/dam/endesa-com/endesaclientes/blog/imagenes/tipos-cables1248x616.jpg"
            className="h-[20vh] w-full object-cover md:h-full"
          />

          <div className="p-4 text-center sm:p-6 md:col-span-2 lg:p-8">

            <h2 className="font-bold lg:text-[3vw] text-[5vw]">
              Elektrik kabellərinin rahat alışının tək ünvanı
            </h2>
            <span className="mt-4 block text-sm">
              Kabelmarket.az - Hər növ kabellərin satışı
            </span>

            <a
              className="mt-8 inline-block w-full bg-yellow-400 py-4 text-md font-bold uppercase tracking-widest text-white"
              href="/allproducts"
            >
              Bütün məhsullar
            </a>
          </div>
        </section>
      </div>
    </>
  );
};

export default Banner;
