import React from "react";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import kabelmarketlogo from "../assets/kabelmarketlogo.png";

const Footer = () => {
  const categories = useSelector((state) => state.category.data);
  const dispatch = useDispatch();

  const { name = "all", category = "all" } = useParams();

  const getFilterUrl = (filter) => {
    const filterCategory = filter.category || category;
    const filterName = filter.name || name;
    return `/search/category/${filterCategory}/name/${filterName}`;
  };

  return (
    <footer className="bg-[#242424]">
      <div className="max-w-[90vw] w-[100%] px-4 py-16 sm:py-6 mx-auto sm:px-6 lg:px-8">
        <div className="w-[100%] h-[100%] sm:flex justify-between">
          <div>
            <div>
              <Link to="/" className="flex flex-shrink-0 items-center">
                <img
                  className="w-[250px] h-[20vh]"
                  src={kabelmarketlogo}
                  alt="kabelmarket.az"
                />
              </Link>
            </div>
            <p className="max-w-xs mt-4 text-sm text-[#FFF]">
              Hər növ kabellərin satışı
            </p>
            <div className="flex mt-8 mb-8 space-x-6 text-[#FFF]">
              <a
                className="hover:text-yellow-400"
                href="/"
                target="_blank"
                rel="noreferrer"
              >
                <BsFacebook />
              </a>
              <a
                className="hover:text-yellow-400"
                href="https://www.instagram.com/kabelmarket.az/"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-2">
            <div className="sm:mt-5 sm:ml-5">
              <p className="font-medium text-[#FFF] text-[24px]">Məhsullar</p>
              <div className="flex flex-col mt-4 space-y-2 text-sm text-[#FFF]">
                {categories?.map((category) => (
                  <Link
                    to={getFilterUrl({ category: category?.title })}
                    key={category?._id}
                  >
                    {category?.title}{" "}
                    <span className="text-yellow-400">
                      ({category?.productCount})
                    </span>
                  </Link>
                ))}
              </div>
            </div>

            <div className="sm:mt-5 sm:ml-5">
              <p className="font-medium text-[#FFF] text-[24px]">Əlaqə</p>
              <nav className="flex flex-col mt-4 space-y-2 text-sm text-[#FFF]">
                <a className="hover:text-yellow-400" href="tel:+994504071417">
                  Tel: (+994) 50 407 14 17
                </a>
                <a
                  className="hover:text-yellow-400"
                  href="mailto:info@kabelmarket.az"
                >
                  Email: info@kabelmarket.az
                </a>
                <a
                  className="hover:text-yellow-400"
                  href="https://maps.google.com/maps?q=40.5865747,49.6330819"
                >
                  Ünvan: Bakı şəh., Sabunçu ray., 1-ci Zabrat qəs., 18 Oktyabr
                  küçəsi, 65
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col items-end justify-between">
          <p className="mt-8 sm:mb-0 mb-2 text-xs text-[#FFF]">© 2023 Kabel Market</p>
          <div className="sm:text-xs text-[8px] text-[#FFF]">
            <span>created by</span>
            <a
              href="https://www.linkedin.com/in/asimmahmudov/"
              className="mt-8 mx-1 sm:text-xs text-[8px] text-yellow-400"
            >
              Asim Mahmudov
            </a>
            <span>and</span>
            <a
              href="https://www.linkedin.com/in/vugar-h-7518b7212/"
              className="mt-8 mx-1 sm:text-xs text-yellow-400"
            >
              Vugar Hasanov
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
