import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  basket: JSON.parse(localStorage.getItem("cart")) || [],
  loading: false,
  error: null,
  orderSuccess: false,
  orderError: false,
};

export const orderCreate = createAsyncThunk(
  "order/orderCreate",
  async (order) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `https://data.kabelmarket.az/api/order/user/create`,
        order,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);



const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    add(state, action) {
      const newItem = action.payload;
      
      const existingItem = state.basket.find(
        (item) => item._id === newItem._id
      );


      if (!existingItem) {
        state.basket.push(newItem);
        localStorage.setItem("cart", JSON.stringify(state.basket));
      }
    },
    remove(state, action) {
     const {id} = action.payload;
      state.basket = state.basket.filter((item) => item._id !== id);
      localStorage.setItem("cart", JSON.stringify(state.basket));
    },
    updateQuantity(state, action) {
      const { id, qty } = action.payload;
      const existingItem = state.basket.find((item) => item._id === id);
    
      if (existingItem) {
        existingItem.qty = qty;
        localStorage.setItem("cart", JSON.stringify(state.basket));
      } else {
        // Handle the case where the item with the given id is not found
        console.error(`Item with id ${id} not found in the cart.`);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(orderCreate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(orderCreate.fulfilled, (state, action) => {
      state.loading = false;
      state.orderSuccess = true;
      state.basket = [];
      localStorage.removeItem("cart");
    });
    builder.addCase(orderCreate.rejected, (state, action) => {
      state.loading = false;
      state.orderError = true;
      state.orderSuccess = false;
    });
  }
});

export const { add, remove, updateQuantity } = cartSlice.actions;
export default cartSlice.reducer;
