import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  data: null,
  loading: false,
  error: null,
  productDetails: null,
  productDetailsLoading: false,
  productDetailsError: null,
};

export const productList = createAsyncThunk(
  'product/productList',
  async ({name = '', category = ''}, { rejectWithValue, getState }) => {
    try {
      const access_token = 'token';
      const response = await axios.get(`https://data.kabelmarket.az/api/product/list?name=${name}&category=${category}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  },
);



export const productDetails = createAsyncThunk(
  'product/productDetails',
  async ({ slug }, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://data.kabelmarket.az/api/product/details/${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  },
);


// Create the auth slice
const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(productList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(productList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(productList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(productDetails.pending, (state) => {
        state.productDetailsLoading = true;
        state.productDetailsError = null;
      })
      .addCase(
        productDetails.fulfilled,
        (state, action) => {
          state.productDetailsLoading = false;
          state.productDetails = action.payload;
        },
      )
      .addCase(productDetails.rejected, (state, action) => {
        state.productDetailsLoading = false;
        state.productDetailsError = action.payload;
      })
  },
});

// Export actions and reducer
export const productactions = productSlice.actions;
export default productSlice.reducer;
