import React from "react";
import amal from "../images/amal.png"
import qmz from "../images/qmz.png"
import estet from "../images/estet.png"
import sabah from "../images/sabah.png"
import asc from "../images/asc.png"
import iek from "../images/iek.png"
import jysk from "../images/jysk.png"
import shinkar from "../images/shinkar.png"
import nurmmc from "../images/nurmmc.png"

const Partners = () => {
  return (
    <>
      <section>
        <div className="py-4 lg:py-16 flex justify-center items-center flex-col mx-auto w-[100%] px-4 bg-[#FFF]">
          <div className="grid w-[89%] grid-cols-2 gap-8 text-gray-500 sm:gap-8 md:grid-cols-3 lg:grid-cols-8">
            <a href="#" className="flex lg:justify-start justify-center items-center">
              <img className="h-12 " src={qmz} alt="partners"/>
            </a>
            <a href="#" className="flex justify-center items-center">
              <img className="h-12 " src={estet} alt="partners"/>
            </a>
            <a href="#" className="flex justify-center items-center">
              <img className="h-12 " src={sabah} alt="partners"/>
            </a>
            <a href="#" className="flex justify-center items-center">
              <img className="h-12 " src={asc} alt="partners"/>
            </a>
            <a href="#" className="flex justify-center items-center">
              <img className="h-12 " src={iek} alt="partners"/>
            </a>
            <a href="#" className="flex justify-center items-center">
              <img className="h-12 " src={jysk} alt="partners"/>
            </a>
            <a href="#" className="flex justify-center items-center">
              <img className="h-12 " src={amal} alt="partners"/>
            </a>
            <a href="#" className="flex justify-center items-center">
              <img className="h-12 " src={shinkar} alt="partners"/>
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Partners;
