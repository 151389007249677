import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "react-phone-number-input/style.css";
import { useSelector, useDispatch } from "react-redux";
import { remove, orderCreate, updateQuantity } from "../redux/slices/cartSlice";
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Basket = () => {
  const cart = useSelector((state) => state.cart.basket);
  const { orderSuccess, orderError } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const toPrice = (num) => Number(num.toFixed(2));

  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [totalPrice, setTotalPrice] = useState(0);

  const handleQuantityUpdate = (id, quantity) => {
    dispatch(updateQuantity({ id, qty: quantity }));
  };

  useEffect(() => {
    const newTotalPrice = cart.reduce(
      (acc, product) => acc + product.price * product.qty,
      0
    );
    setTotalPrice(toPrice(newTotalPrice));
  }, [cart]);

  const handleDelete = (id) => () => {
    // Delete an item from the cart
    dispatch(remove({ id }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedCart = JSON.parse(localStorage.getItem("cart"));
    const order = {
      orderItems: updatedCart,
      totalPrice,
      customer: {
        name,
        phoneNumber: number,
      },
    };
    try {
      dispatch(orderCreate(order));
      swal('Uğurlu!', 'Uğurlu bir şəkildə sifariş qeydə alındı!', 'success').then(
        () => {
          window.location.replace('/allproducts');
        }
      );
      if (orderError) {
        Swal({
          title: "Əməliyyat uğursuz oldu",
          text: "Yenidən yoxlayın",
          icon: "error",
        });
      }
    }catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <Navbar />
      <div className="my-[15px] min-h-[100vh] h-[100%]">
        <h1 className="mb-10 text-center text-2xl font-bold">Səbətim</h1>
        {cart.length === 0 ? (
          <p className="text-center">Səbətiniz boşdur.</p>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="w-auto md:w-[100%] md:items-start items-center justify-center flex flex-col-reverse md:flex-row"
          >
            <div className="md:w-auto w-[90%] justify-between max-h-[80vh] overflow-y-scroll sm:px-4 sm:my-0 my-10 sm:mx-5">
              {cart.map((product) => (
                <li className="mb-6 rounded-lg bg-white sm:p-6 p-2 shadow-md flex justify-around w-[100%] md:w-[50vw] relative">
                  <div className="flex sm:w-[80%] w-[60%] ml-[1vw]">
                    <img
                      src={product?.image}
                      alt={product?.title}
                      className="h-16 w-16 rounded object-contain"
                    />
                    <div className="flex flex-col ml-[1vw]">
                      <h3 className="sm:text-[1.2vw] text-[3.5vw] text-[#242424] font-bold">
                        {product?.title}
                      </h3>

                      <dl className="mt-0.5 space-y-px sm:text-[14px] text-[2vw] text-gray-600">
                        {product?.category}
                      </dl>
                    </div>
                  </div>

                  <div className="flex sm:w-[30%] w-[50%] items-center justify-around gap-2">
                    <div>
                      <label for="Line1Qty" className="sr-only">
                        {" "}
                        Quantity{" "}
                      </label>

                      <input
                        type="number"
                        min="1"
                        step="any"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue === "0") {
                            e.target.value = "1";
                          }
                          handleQuantityUpdate(product?._id, e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        required
                        defaultValue={1}
                        value={product?.qty}
                        id="Line1Qty"
                        className="h-8 w-12 text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary rounded sm:text-[1.2vw] text-[3vw] text-center  [-moz-appearance:_textfield] focus:outline-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                      />
                      <span className="sm:text-[12px] text-[2.5vw] ml-1">
                        {product?.unit}
                      </span>
                      <span className="sm:text-[12px] text-[2.5vw] ml-1">
                        X
                      </span>
                      <span className="sm:text-[12px] text-[2.5vw] ml-1">
                        {product?.price} azn
                      </span>
                    </div>

                    <button
                      onClick={handleDelete(product?._id)}
                      className="text-red-600"
                    >
                      <span className="sr-only">Remove item</span>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                </li>
              ))}
            </div>
            {/* checkout */}
            <div className="sm:mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-[25vw] w-[90vw]">
              <hr className="my-4" />
              <div className="flex justify-between">
                <p className="text-lg font-bold">Ümumi məbləğ</p>
                <div className="">
                  <p className="mb-1 text-lg font-bold text-green">
                    {totalPrice}AZN
                  </p>
                </div>
              </div>
              <hr className="my-4" />

              <div className="mb-6 mt-8">
                <label className="font-bold ml-1">Adınız və soyadınız</label>
                <input
                  type="text"
                  placeholder="Adınızı və Soyadınızı daxil edin."
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  className="
                        w-full
                        rounded
                        py-3
                        mt-2
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                  required
                />
              </div>

              <div className="mb-6">
                <label htmlFor="phone" className="font-bold mb-2 ml-1">
                  Əlaqə nömrəsi
                </label>
                <PhoneInput
                  international
                  defaultCountry="AZ"
                  onChange={setNumber}
                  id="phone"
                  name="phone"
                  className="numinput"
                  required
                />
              </div>

              <div className="mt-6">
                <button
                  type="submit"
                  className=" w-full rounded-md bg-green-500 py-4 font-medium text-[20px] text-[#242424] hover:bg-green-600"
                >
                  Sifariş et
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Basket;
