import React from "react";
import { useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import logo from "../assets/kabelmarketlogo.png"

const Contact = () => {
  const [phoneNum, setPhoneNum] = useState();
  const [sended, setSended] = useState(false);
  emailjs.init("Pd3WtZz0f1w-1UFUd");
  const handleSubmit = async (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_wml70da",
        "template_ytjee5f",
        e.target,
        "Pd3WtZz0f1w-1UFUd"
      )
      .then(
        (result) => {
          setSended(true);
          swal(
            `Mesajınız uğurla göndərildi`,
            `Sizinlə tezliklə əlaqə saxlanılacaq`,
            "success"
          );
        },
        (error) => {
          swal(`Əməliyyat uğursuz oldu`, `Yenidən yoxlayın`, "error");
        }
      );
  };

  return (
    <>
      <section
        id="contact"
        className="carousBg max-w-[90vw] w-[100%] h-[100%] px-0 py-16 mx-auto sm:px-6 lg:px-8 flex flex-col justify-center items-center"
      >
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-[30px] w-[100%] text-left">
          Bizimlə əlaqə
        </h2>
        <div className="lg:grid min-h-[40vh] w-[100%] shadow-2xl lg:grid-cols-12">
          <section className="relative lg:flex hidden h-[20vh] items-end bg-gray-950 lg:col-span-5 lg:h-full xl:col-span-6">
            <img
              alt="Kabel"
              src="https://media.tenor.com/J59b7Q5-FRYAAAAd/noir-et-blanc-vie-cables.gif"
              className="absolute inset-0 h-full w-full object-cover opacity-80"
            />

            <div className="hidden lg:relative lg:block lg:p-12">
              <h2 className="mt-6 text-2xl font-bold text-[#fff] sm:text-3xl md:text-4xl">
                Kabelmarket.az - Hər növ kabellərin satışı
              </h2>

              <p className="mt-4 leading-relaxed text-[#fff]">
                ELEKTRIK KABELLƏRININ RAHAT ALIŞININ TƏK ÜNVANI
              </p>
            </div>
          </section>

          <main className="flex carousBg items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
            <div className="max-w-xl lg:max-w-3xl">
              <div className="relative -mt-8 block lg:hidden">
                <p className="mt-4 leading-relaxed text-sm text-[#242424]">
                  ELEKTRIK KABELLƏRININ RAHAT ALIŞININ TƏK ÜNVANI - <span className="font-bold">kabelmarket.az</span>
                </p>
              </div>

              <form
                onSubmit={handleSubmit}
                className="mt-8 grid grid-cols-6 gap-6"
              >
                <div className="col-span-6 sm:col-span-3">
                  <label
                    for="FirstName"
                    className="block text-md font-medium text-gray-700"
                  >
                    Ad
                  </label>
                  <input
                    type="text"
                    placeholder="Adınızı daxil edin"
                    name="name"
                    id="name"
                    className="
                        w-full
                        rounded
                        py-3
                        mt-2
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                    required
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    for="LastName"
                    className="block text-md font-medium text-gray-700"
                  >
                    Soyad
                  </label>

                  <input
                    type="text"
                    placeholder="Soydınızı daxil edin"
                    name="surname"
                    id="surname"
                    className="
                        w-full
                        rounded
                        py-3
                        mt-2
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                    required
                  />
                </div>

                <div className="col-span-6">
                  <label
                    for="number"
                    className="block text-md font-medium text-gray-700"
                  >
                    Əlaqə nömrəsi
                  </label>
                  <PhoneInput
                    international
                    defaultCountry="AZ"
                    onChange={setPhoneNum}
                    id="phone"
                    name="phone"
                    className="numinput"
                    required
                  />
                </div>

                <div className="col-span-6">
                  <label
                    for="message"
                    className="block text-md font-medium text-gray-700"
                  >
                    Mesajınız
                  </label>

                  <textarea
                    name="message"
                    id="message"
                    cols="10"
                    rows="4"
                    placeholder="Mesajınızı daxil edin"
                    className="
                        w-full
                        rounded
                        py-3
                        mt-2
                        px-[14px]
                        text-body-color text-base
                        border border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                    required
                  ></textarea>
                </div>

                <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                  <button
                    type="submit"
                    className="inline-block w-[100%] shrink-0 rounded-md border border-yellow-400 bg-yellow-400 font-bold px-12 py-3 text-sm text-[#242424] transition hover:bg-transparent hover:text-yellow-400 active:text-yellow-400"
                  >
                    Göndər
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
      </section>
    </>
  );
};

export default Contact;
