import React from 'react'
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import CarouselProduct from '../components/Carousel'
import LatestProducts from './LatestProducts'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import Contact from '../components/Contact'
import Partners from '../components/Partners'
import Advantages from '../components/Advantages'

const Home = () => {
  return (
    <div>
        <Header/>
        <Navbar/>
        <CarouselProduct/>
        <Advantages/>
        <LatestProducts/>
        <Banner/>
        <Contact/>
        <Partners/>
        <Footer/>
    </div>
  )
}

export default Home