import React, { Fragment, useEffect } from "react";
import { PiShoppingCartBold } from "react-icons/pi";
import { Menu, Transition } from "@headlessui/react";
import { TbCategory } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";
import { categoryList } from "../redux/slices/categorySlice";
import { Link, useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DesktopMenu = () => {
  const categories = useSelector((state) => state.category.data);
  const dispatch = useDispatch();

  const { name = "all", category = "all" } = useParams();

  const getFilterUrl = (filter) => {
    const filterCategory = filter.category || category;
    const filterName = filter.name || name;
    return `/search/category/${filterCategory}/name/${filterName}`;
  };

  useEffect(() => {
    dispatch(categoryList());
  }, [dispatch]);

  return (
    <div className="hidden sm:flex justify-center items-center w-[100%]">
      <div className="hidden sm:flex h-[8vh] w-[90vw] pl-[20px] items-center border-b-[1.5px] justify-start">
        <Menu
          as="div"
          className="relative inline-block text-left mx-4 bg-yellow-400 rounded-[8px]"
        >
          <div>
            <Menu.Button className="flex items-center w-full justify-center gap-x-1.5 rounded-md text-[#242424] px-3 py-2 text-[18px] border-none">
              Kateqoriyalar
              <TbCategory className="mr-1 h-5 w-5 text-[#242424] text-[30px]" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-10 mt-2 min-w-[15vw] w-100% origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {categories?.map((category) => (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={getFilterUrl({ category: category?.title })}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        {category?.title}{" "}
                        <span className="text-yellow-400">
                          ({category?.productCount})
                        </span>
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        <div className="flex space-x-4">
          <a
            href="/allproducts"
            className="text-[#242424] px-3 py-2 text-[18px]"
          >
            Məhsullar
          </a>
        </div>

        <div className="flex space-x-4">
          <a href="/#about" className="text-[#242424] px-3 py-2 text-[18px]">
            Haqqımızda
          </a>
        </div>
      </div>
    </div>
  );
};

export default DesktopMenu;
