import React from "react";

const Loader = () => {
  return (
    <div className="w-[100vw] h-[100vh] flex justify-center items-center fixed top-0 left-0 bg-[#FFF] z-[1000000]">
      <div className="flex flex-row gap-2">
        <div className="w-5 h-5 rounded-full bg-yellow-400 animate-bounce [animation-delay:.7s]"></div>
        <div className="w-5 h-5 rounded-full bg-yellow-400 animate-bounce [animation-delay:.3s]"></div>
        <div className="w-5 h-5 rounded-full bg-yellow-400 animate-bounce [animation-delay:.7s]"></div>
      </div>
    </div>
  );
};

export default Loader;
