import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { productDetails } from "../redux/slices/productSlice";
import { add } from "../redux/slices/cartSlice";
import Loader from "../components/Loader";

const ProductDetail = () => {
  const { slug } = useParams();
  const [qty, setQty] = useState(1);

  const {
    productDetails: product,
    productDetailsLoading: loading,
    productDetailsError: error,
  } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(productDetails({ slug }));
  }, [dispatch, slug]);

  const cart = useSelector((state) => state.cart.basket);

  const isCart = cart.find((item) => item?._id === product?._id);

  const addToCart = () => {
    dispatch(add({ ...product, qty }));
  };

  return (
    <>
      <Header />
      <Navbar />
      {loading && <Loader />}
      {error && <p>{error}</p>}
      <div>
        <section className="text-gray-700 body-font overflow-hidden bg-white">
          <div className="container px-5 py-24 mx-auto">
            <div className="lg:w-4/5 mx-auto flex flex-wrap">
              <img
                alt="ecommerce"
                className="lg:w-1/2 w-full h-[50vh] object-contain object-center rounded border border-gray-200"
                src={product?.image}
              />
              <form
                onSubmit={addToCart}
                className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0"
              >
                <h2 className="text-sm title-font text-gray-500 tracking-widest">
                  {product?.category}
                </h2>
                <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                  {product?.title}
                </h1>
                <p
                  className="my-[10px] text-[18px]"
                  dangerouslySetInnerHTML={{ __html: product?.description }}
                ></p>
                <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
                  <div className="flex items-center">
                    <div className="relative mr-2">
                      <input
                        step="any"
                        type="number"
                        defaultValue={1}
                        min={0}
                        value={qty}
                        onChange={(e) => {
                          let newValue = e.target.value;
                          if (newValue === "0") {
                            newValue = "1";
                          }
                          setQty(newValue);
                        }}
                        className="h-8 w-12 text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary rounded sm:text-[1.2vw] text-[3vw] text-center  [-moz-appearance:_textfield] focus:outline-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                        onKeyPress={(e) => {
                          if (e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        required
                      />
                    </div>
                    <span className="mr-3">{product?.unit}</span>
                  </div>
                </div>
                <div className="flex">
                  <span className="title-font font-medium text-2xl text-gray-900">
                    {product?.price}AZN
                  </span>
                  {isCart ? (
                    <button
                      disabled
                      className="flex ml-auto text-white bg-red-300 border-0 py-2 px-6 focus:outline-none rounded"
                    >
                      Səbətdə
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="flex ml-auto text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded"
                    >
                      Səbət əlavə et
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetail;
