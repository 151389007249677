import React, { useEffect, useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import slide1 from "../assets/slide1.jpg";
import mobileslide1 from "../assets/mobileslide1.jpg";
import mobileslide2 from "../assets/mobileslide2.jpg";
import slide2 from "../assets/slide2.jpg";
import yanteref from "../assets/yansehife.jpg";

const CarouselProduct = () => {
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  const desktopSlides = [
    {
      url: slide1,
    },
    {
      url: slide2,
    },
  ];

  const mobileSlides = [
    {
      url: mobileslide1,
    },
    {
      url: mobileslide2,
    },
  ];

  const slides = isMobile ? mobileSlides : desktopSlides;

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [currentIndex, nextSlide]);

  return (
    <div className="w-[100%] h-auto flex justify-center carouselBg">
      <div className="max-w-[95vw] sm:max-w-[62vw] sm:h-[60vh] h-[40vh] w-full py-5 px-2 relative group">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
          className="w-full h-full rounded-2xl bg-center bg-cover duration-500"
        ></div>
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactLeft onClick={prevSlide} size={30} />
        </div>
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactRight onClick={nextSlide} size={30} />
        </div>
      </div>
      <img
        src={yanteref}
        className="hidden sm:flex justify-end items-end sm:max-w-[25vw] sm:h-[60vh] h-[40vh] w-full py-5 px-2 relative group rounded-lg"
        alt=""
      />
    </div>
  );
};

export default CarouselProduct;
