import React from "react";
import protect from "../images/icon-protect.png"
import tag from "../images/icon-tag.png"
import reload from "../images/icon-reload.png"
import rocket from "../images/icon-rocket.png"
import support from "../images/icon-support.png"

const Advantages = () => {
  return (
    <>
      <section className="sm:min-h-[25vh] min-h-[30vh] flex justify-center items-end">
        <div className="py-4 lg:py-16 flex justify-center items-center flex-col mx-auto w-[100%] px-4">
          <div className="grid w-[89%] grid-cols-2 mx-auto gap-8 text-gray-500 sm:gap-8 md:grid-cols-3 lg:grid-cols-4">
            <div href="#" className="flex sm:flex-row flex-col sm:w-[20vw] justify-center items-center">
              <img className="sm:h-10 h-[3.5vh]" src={protect} alt="partners"/>
              <p className="sm:ml-3 font-bold sm:mt-0 mt-[10px] text-center sm:text-[15px] text-[3vw]">100% təhlükəsiz ödəniş</p>
            </div>
            <div href="#" className="flex sm:flex-row flex-col sm:w-[20vw] justify-center items-center">
              <img className="sm:h-10 h-[3.5vh]" src={tag} alt="partners"/>
              <p className="sm:ml-3 font-bold sm:mt-0 mt-[10px] text-center sm:text-[15px] text-[3vw]">24/7 əlaqə imkanı</p>
            </div>
            <div href="#" className="flex sm:flex-row flex-col sm:w-[20vw] justify-center items-center">
              <img className="sm:h-10 h-[3.5vh]" src={rocket} alt="partners"/>
              <p className="sm:ml-3 font-bold sm:mt-0 mt-[10px] text-center sm:text-[15px] text-[3vw]">Çox məhsul alışında endirimlər</p>
            </div>
            <div href="#" className="flex sm:flex-row flex-col sm:w-[20vw] justify-center items-center">
              <img className="sm:h-10 h-[3.5vh]" src={support} alt="partners"/>
              <p className="sm:ml-3 font-bold sm:mt-0 mt-[10px] text-center sm:text-[15px] text-[3vw]">Münasib qiymətlər</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Advantages;
