import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Product from "../components/Product";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { productList } from "../redux/slices/productSlice";
import { categoryList } from "../redux/slices/categorySlice";

const AllProducts = () => {
  const products = useSelector((state) => state.products.data);
  const categories = useSelector((state) => state.category.data);
  const dispatch = useDispatch();

  const { name = "all", category = "all" } = useParams();

  const getFilterUrl = (filter) => {
    const filterCategory = filter.category || category;
    const filterName = filter.name || name;
    return `/search/category/${filterCategory}/name/${filterName}`;
  };

  const selectedCategory = categories?.find((cat) => cat.title === category);

  useEffect(() => {
    dispatch(categoryList());
    dispatch(
      productList({
        name: name !== "all" ? name : "",
        category: category !== "all" ? category : "",
      })
    );
  }, [dispatch, category, name]);

  return (
    <div>
      <Header />
      <Navbar />
      <section>
        <div className="flex lg:flex-row flex-col mx-auto w-[90%] px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <div className="hidden lg:grid w-[25vw] lg:grid-cols-1 mx-4 lg:items-start lg:gap-8">
            <div className="space-y-4">
              <div>
                <div className="space-y-2">
                  <div className="rounded border border-gray-300">
                    <div className="flex items-center justify-between gap-2 p-4 text-gray-900 transition">
                      <span className="text-sm font-medium">
                        {" "}
                        Kategoriyalar{" "}
                      </span>
                    </div>

                    <div className="border-t border-gray-200 bg-white">
                      <header className="flex items-center justify-center p-4">
                        <Link
                          to="/allproducts"
                          className="sm:text-[0.9vw] text-[3.5vw] text-center underline-none rounded-[6px] text-[#242424] w-[90%] bg-yellow-400 p-2"
                        >
                          Sıfırla
                        </Link>
                      </header>

                      <ul className="space-y-1 border-t border-gray-200 p-4">
                        {categories?.map((category) => (
                          <li key={category?._id}>
                            <Link
                              to={getFilterUrl({ category: category?.title })}
                            >
                              <label
                                for={category?._id}
                                className="inline-flex items-center gap-4 cursor-pointer"
                              >
                                <input
                                  type="checkbox"
                                  id={category?._id}
                                  checked={
                                    category.title === selectedCategory?.title
                                  }
                                  className="h-5 w-5 rounded border-gray-300"
                                />

                                <span className="sm:text-[0.9vw] text-[3.5vw] font-medium text-gray-700">
                                  {category?.title}{" "}
                                  <span className="text-yellow-400">
                                    ({category?.productCount})
                                  </span>
                                </span>
                              </label>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="my-2 lg:hidden grid lg:grid-cols-4 lg:items-start lg:gap-8">
            <div className="space-y-4">
              <details className="overflow-hidden rounded border border-gray-300 [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between gap-2 p-4 text-gray-900 transition">
                  <span className="text-sm font-medium"> Kateqoriyalar </span>

                  <span className="transition group-open:-rotate-180">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="h-4 w-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </summary>

                <div className="border-t border-gray-200 bg-white">
                  <header className="flex items-center justify-center p-4">
                    <Link
                      to="/allproducts"
                      className="sm:text-[0.9vw] text-[3.5vw] text-center underline-none rounded-[6px] text-[#242424] w-[90%] bg-yellow-400 p-2"
                    >
                      Sıfırla
                    </Link>
                  </header>
                  <ul className="space-y-1 border-t border-gray-200 p-4">
                    {categories?.map((category) => (
                      <li>
                        <Link to={getFilterUrl({ category: category?.title })}>
                          <label
                            for={category?._id}
                            className="inline-flex items-center gap-4 cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              id={category?._id}
                              checked={
                                category.title === selectedCategory?.title
                              }
                              className="h-5 w-5 rounded border-gray-300"
                            />

                            <span className="sm:text-[0.9vw] text-[3.5vw] font-medium text-gray-700">
                              {category?.title}{" "}
                              <span className="text-yellow-400">
                                ({category?.productCount})
                              </span>
                            </span>
                          </label>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </details>
            </div>
          </div>

          <div className="lg:col-span-3 w-[100%]">
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              {products?.length === 0 && (
                <div className="text-center flex col-span-3 gap-2 font-bold">
                  Məhsul tapılmadı
                </div>
              )}
              {products?.map((product) => (
                <Product
                  product={product}
                  key={product?._id}
                  newProducts={products?.slice(0, 4)}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AllProducts;
