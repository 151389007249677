import React, { useEffect } from "react";
import Product from "../components/Product";
import { useDispatch, useSelector } from "react-redux";
import { productList } from "../redux/slices/productSlice";

const LatestProducts = () => {
  const {data, loading}= useSelector((state) => state.products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productList(
      {
        name: "",
        category: "",
      }
    ));
  }, [dispatch]);

  if(loading) return <div className="text-center">Loading...</div>

  return (
    <div className="bg-white w-[100%]">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 lg:max-w-[90vw] lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900 mb-[30px]">
          Ən son əlavə olunanlar
        </h2>
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {data?.slice(0, 4)?.map((product) => (
            <Product
              product={product}
              key={product?._id}
              newProducts={data?.slice(0, 4)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestProducts;
