import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { add } from "../redux/slices/cartSlice";
import { useState } from "react";
import { Link } from "react-router-dom";
const Product = ({ product, newProducts }) => {
  const dispatch = useDispatch();
  const isNew = newProducts.includes(product);
  const [qty, setQty] = useState(1);

  const cart = useSelector((state) => state.cart.basket);

  const isCart = cart.find((item) => item?._id === product?._id);

  const addToCart = () => {
    dispatch(add({ ...product, qty }));
  };

  const handleOrderWhatsapp = () => {
    const message = `Salam, Xoş gördük!\nTest mehsul 1 məhsulunu sifariş etmək istəyirəm.\nMəhsulun linki: https://kabelmarket.az/product/test-mehsul-1`;
    const encodedMessage = encodeURIComponent(message);
    window.open(
      `https://api.whatsapp.com/send?phone=994504071417&text=${encodedMessage}`
    );
  };

  return (
    <div className="justify-center border border-gray-200 shadow-lg">
      <Link
        to={`/product/${product?.slug}`}
        className="group relative block overflow-hidden"
      >
        <div
          className="aspect-h-1 aspect-w-1 w-full overflow-hidden bg-gray-200 xl:aspect-h-8 xl:aspect-w-7"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={product?.image}
            alt="Tall slender porcelain bottle with natural clay textured body and cork stopper."
            className="h-78 w-245 max-w-[300px] h-[300px] object-cover object-center group-hover:scale-125 transition-transform duration-300"
          />
        </div>

        <div className="relative border border-gray-100 bg-white p-6">
          {isNew && (
            <span className="whitespace-nowrap bg-yellow-400 px-3 py-1.5 rounded-[6px] text-xs font-medium">
              YENİ
            </span>
          )}

          <h3 className="mt-4 text-lg font-medium text-gray-900">
            {product?.title}
          </h3>

          <p className="mt-1.5 text-sm text-gray-700">
            (1 {product?.unit}) Qiyməti: {product?.price}AZN
            <br />
            {product?.category}
          </p>
        </div>
      </Link>
      <div className="p-[5px] bg-red flex flex-col justify-around">
        <button
          onClick={handleOrderWhatsapp}
          className="p-[8px] bg-[#25d366] text-white rounded-[6px] flex items-center justify-center text-[15px] m-[2px] relative"
        >
          <BsWhatsapp className="absolute left-[10px] text-[22px] text-white" />
          Whatsappla sifariş
        </button>
        {isCart ? (
          <button
            disabled
            onClick={addToCart}
            className="p-[8px] bg-yellow-600 text-white rounded-[6px] flex items-center justify-center text-[15px] m-[2px] relative"
          >
            Səbətdə
          </button>
        ) : (
          <button
            onClick={addToCart}
            className="p-[8px] bg-yellow-400 text-[#000] rounded-[6px] flex items-center justify-center text-[15px] m-[2px] relative"
          >
            <AiOutlinePlusCircle className="absolute left-[10px] text-[22px] text-[#000]" />{" "}
            Səbətə əlavə et
          </button>
        )}
      </div>
    </div>
  );
};

export default Product;
