import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Basket from "./pages/Basket";
import Home from "./pages/Home";
import ProductDetail from "./pages/ProductDetail";
import { useEffect, useState } from "react";
import Loader from "./components/Loader";
import AllProducts from "./pages/AllProducts";
import NotFound from "./components/NotFound";

function App() {


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);


  return (
    <BrowserRouter>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/basket" element={<Basket />} />
        <Route path="/product/:slug" element={<ProductDetail />} />
        <Route path="/allproducts" element={<AllProducts />} />
        <Route path="/search/name/:name" element={<AllProducts />} exact />
        <Route
          path="/search/category/:category"
          element={<AllProducts />}
          exact
        ></Route>
        <Route
          path="/search/category/:category/name/:name"
          element={<AllProducts />}
          exact
        ></Route>
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
