import React, { Fragment, useEffect, useState } from "react";
import { PiShoppingCartBold } from "react-icons/pi";
import { Menu, Transition } from "@headlessui/react";
import { TbCategory } from "react-icons/tb";
import DesktopMenu from "./DesktopMenu";
import { useSelector, useDispatch } from "react-redux";
import { categoryList } from "../redux/slices/categorySlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import kabelmarketlogo from "../assets/kabelmarketlogo.png";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const cart = useSelector((state) => state.cart.basket);
  const categories = useSelector((state) => state.category.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(categoryList());
  }, [dispatch]);
  const toggleMobileMenu = () => {
    const mobileMenu = document.getElementById("mobile-menu");
    mobileMenu.classList.toggle("hidden");
  };

  const { catname = "all", category = "all" } = useParams();

  const getFilterUrl = (filter) => {
    const filterCategory = filter.category || category;
    const filtercatName = filter.catname || catname;
    return `/search/category/${filterCategory}/name/${filtercatName}`;
  };

  const navigate = useNavigate();
  const [name, setName] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();
    if (name === "") return;
    navigate(`/search/name/${name}`);
  };

  return (
    <>
      <nav className="bg-[#FFF] sticky w-[100%] top-0 z-[9999] shadow-md">
        <div className="mx-auto sm:max-w-[90vw] px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                className="relative inline-flex items-center justify-center rounded-md p-2 bg-yellow-400 text-[#242424] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white outline-none"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={toggleMobileMenu}
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>
                <svg
                  className="block h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
                <svg
                  className="hidden h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <Link to="/" className="flex flex-shrink-0 items-center">
                <img
                  className="w-[150px] lg:w-[160px] h-[11vh]"
                  src={kabelmarketlogo}
                  alt="kabelmarket.az"
                />
              </Link>

              <div className="hidden sm:ml-6 sm:flex w-[100%] items-center justify-center">
                <form
                  onSubmit={submitHandler}
                  className="w-[100%] max-w-[700px] border-[1px] border-[#E4E4E4] rounded-[20px]"
                >
                  <label
                    for="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only"
                  >
                    Search
                  </label>
                  <div className="relative h-[100%] w-[100%] flex items-center">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </div>
                    <input
                      type="search"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="default-search"
                      className="block outline-none p-[12px] pl-10 w-full text-sm text-[#242424] bg-gray-1000 border-none rounded-[20px]"
                      placeholder="Məhsulu axtar..."
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="flex inset-y-0 right-0 items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <a
                href="/basket"
                className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-yellow-400 transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group"
              >
                <span className="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-yellow-400 group-hover:h-full"></span>
                <span className="absolute right-0 pr-1 duration-200 ease-out group-hover:translate-x-12">
                  <PiShoppingCartBold className="mx-[10px] text-[30px] text-[#242424]" />
                </span>
                <span className="absolute left-0 pl-[0] -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                  <PiShoppingCartBold className="mx-[10px] text-[30px] text-[#242424]" />
                </span>
                <span className="relative w-full text-left text-[3vw] sm:text-[18px] transition-colors duration-200 ease-in-out group-hover:text-white">
                  Səbətim ({cart.length})
                </span>
              </a>
            </div>
          </div>
        </div>

        {/* mobile version */}
        <div className="sm:hidden hidden h-[100%] pb-[25px]" id="mobile-menu">
          <form className="px-2 pt-2 pb-3" onSubmit={submitHandler}>
            <label for="mobile-search" className="sr-only">
              Search
            </label>
            <div className="relative flex items-center">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="mobile-search"
                className="block w-full text-gray-900 bg-gray-1000 rounded-lg border border-none outline-none p-3 pl-10 text-sm"
                placeholder="Search..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </form>
          <div className="space-y-1 px-2 pb-3 pt-2">
            <Menu
              as="div"
              className="relative inline-block text-left   bg-yellow-400 rounded-[8px]"
            >
              <div>
                <Menu.Button className="flex items-center w-full justify-center gap-x-1.5 rounded-md text-[#242424] px-3 py-2 text-[18px] border-none">
                  Kateqoriyalar
                  <TbCategory className="mr-1 h-5 w-5 text-[#242424] text-[30px]" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {categories?.map((category) => (
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={getFilterUrl({ category: category?.title })}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            {category.title}
                            <span className="text-yellow-400">
                              ({category?.productCount})
                            </span>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <a
              href="/allproducts"
              className="text-[#242424] block rounded-md mt-2 px-3 py-2 text-base font-medium"
            >
              Məhsullar
            </a>

            <a
              href="/#about"
              className="text-[#242424] block rounded-md px-3 py-2 text-base font-medium"
            >
              Haqqımızda
            </a>
          </div>
        </div>
      </nav>
      <DesktopMenu />
    </>
  );
};

export default Navbar;
