import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const categoryList = createAsyncThunk(
  'category/categoryList',
  async (_, { rejectWithValue, getState }) => {
    try {
      const access_token = 'token';
      const response = await axios.get(`https://data.kabelmarket.az/api/category/list`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(rejectWithValue(error.response.data));
    }
  },
);


// Create the auth slice
const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(categoryList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(categoryList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(categoryList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

// Export actions and reducer
export const categoryctions = categorySlice.actions;
export default categorySlice.reducer;
